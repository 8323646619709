.products__list {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 1px);
    border-top: 1px solid $colorDark;
    margin-right: -1px
}

.products__info {
    @include media-tablet {
        opacity: 1;
        position: relative;
    }

    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: opacity 0.3s ease-in-out;

    @media (any-hover: hover) {
        &:hover {
         opacity: 1;
        }
    }
}

.products__item {
    @include media-tablet {
        width: 50%;
    }

    width: 25%;
    position: relative;
    border: 0 solid $colorDark;
    border-width: 0 1px 1px 0;
}

.products__img {
    @include media-tablet {
        height: auto;
    }

    height: 100%;
    width: 100%;
    object-fit: cover;
}

.products__expected {
    @include media-tablet {
        top: 13px;
        left: 13px;
    }

    position: absolute;
    top: 11px;
    left: 19px;
}

.expected {
    @include font-body-small;

    text-transform: uppercase;
    letter-spacing: normal;
}

.colors {
    display: flex;
    flex-wrap: wrap;
}

.colors__item {
    margin: 6px;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
}

.products-info {
    @include media-tablet {
        height: auto;
        padding: 1% 3% 2%;
    }

    @include media-mobile {
        height: auto;
        padding: 3% 6% 4%;
    }

    height: 100%;
    padding: 6%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.products-info_disabled {
    @include media-tablet {
        justify-content: space-between;
    }

    justify-content: flex-end;
}

.products-info__main {
    @include media-tablet {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.products-info__main_no-size {
    margin-bottom: 0;
}

.products-info__header {
    @include font-body-large;
    @include media-tablet {
        @include font-body-medium;
    }

    line-height: inherit;
}

.products-info__price {
    @include font-body-high;

    @include media-tablet {
        @include font-body-small;

        color: $colorGray;
    }
}

.products-info__sizes {
    @include media-tablet {
        display: none;
    }

    display: flex;
}

.products-info__size {
    &:not(:last-child) {
        margin-right: 3%;
    }
}

.products-info__size_disabled {
    color: $colorGray;
}

.products-info__colors {
    justify-content: flex-end;
    padding: 10px;

    @include media-tablet {
        display: none;
    }
}

.products-info__sold {
    @include font-body-small;
    
    text-transform: uppercase;
    color: $colorGray;
}