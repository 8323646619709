$colorWhite: #fff;
$colorBlack: #000;
$colorGray: #a4a4a4;
$colorDark: #2e2e2e;
$colorLightBrown: #b3a25f;
$colorPink: #ff70f8;
$colorMilk: #e6d6a8;
$colorDarkBrown: #5b3c11;
$colorPurple: #b07ffa;
$colorBrown: #ba7730;
$colorLightPink: #edb2dd;
$colorOhra: #d6a94f;
$colorYellow: #f7ff00;
$colorTurquoise: #c9ebf5;
$colorBlue: #9cc7e6;